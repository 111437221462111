import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import betternowImage from "./images/get_better.png";
import { Link } from "@mui/material";

const App: React.FC = () => {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Adjust height as needed for full viewport height
      }}
    >
      <h2>404 - Not Found</h2>
      <img
        src={betternowImage}
        alt="Not Found"
        style={{ width: "200px", height: "auto" }}
      />
      <p>The page you're looking for does not exist.</p>
      Back to <Link
            href='/login'
            style={{ textDecoration: "none", color: "blue" }}
          >Login</Link>
    </div>
  );
};

export default App;
