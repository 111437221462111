// conversationReducer.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchConversations,
  fetchChatSessions,
  fetchAnonymizedChatSessions,
  fetchTranscripts,
  fetchComments,
  addComment,
  editComment,
  deleteComment,
} from "../actions/conversationsActions";
import { UserName } from "./userReducer";
import { ChatAnnotation } from "../../components/dashboard/conversations/MessageBubble";

export interface Conversation {
  id: string;
  human: any;
  bot: any;
  config?: any;
  sms_active?: boolean;
  route_to_debug?: boolean;
  introduced_by?: any;
  queue_offset?: number;
  sent_cursor?: any;
  created_at: string;
  version_major?: number;
  version_minor?: number;
  owning_user_id?: string;
  is_private?: boolean;
  environment?: string;
  latest_chat_message_time?: string;
}

export interface Transcript {
  conversation_id: string;
  created_at: string;
  environment: string;
  human_initial: string;
  bot_name: string;
  latest_chat_message_time?: string;
  num_turns_in_latest_session: number;
  is_private?: boolean;
}

export interface ChatSession {
  id: string;
  start_time: string;
  end_time: string;
  messages: any[];
}

export interface Message {
  id: number;
  chat_message_id: number;
  chat_session_id: string;
  created_at: string;
  type: "human" | "bot";
  content: string;
  annotations?: ChatAnnotation[];
  media_ref?: {
    blob_path: string;
    content_type: string;
  };
}

export interface AnnotationUser {
  id: string;
  name: UserName;
}

export interface Comment {
  id: string;
  conversation_id: string;
  target_id?: string;
  user: AnnotationUser;
  comment: string;
  thread_id: string;
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
}

interface ConversationState {
  conversations: Conversation[];
  transcripts: Transcript[];
  anonymizedChatSessions: ChatSession[];
  chatSessions: ChatSession[];
  comments: Comment[];
  loading: boolean;
  loadingTranscript: boolean;
  loadingConversation: boolean;
  loadingComments: boolean;
  error: string | null;
}

const initialState: ConversationState = {
  conversations: [],
  transcripts: [],
  chatSessions: [],
  anonymizedChatSessions: [],
  comments: [],
  loading: false,
  loadingTranscript: false,
  loadingConversation: false,
  loadingComments: false,
  error: null,
};

const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchConversations action
      .addCase(fetchConversations.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.conversations = [];
      })
      .addCase(
        fetchConversations.fulfilled,
        (state, action: PayloadAction<Conversation[]>) => {
          state.loading = false;
          state.conversations = action.payload;
        }
      )
      .addCase(fetchConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch conversations.";
      })
      // Handle fetchChatSessions action
      .addCase(fetchChatSessions.pending, (state) => {
        state.loadingConversation = true;
        state.error = null;
        state.chatSessions = [];
      })
      .addCase(
        fetchChatSessions.fulfilled,
        (state, action: PayloadAction<ChatSession[]>) => {
          state.loadingConversation = false;
          state.chatSessions = action.payload;
        }
      )
      .addCase(fetchChatSessions.rejected, (state, action) => {
        state.loadingConversation = false;
        state.error = action.error.message || "Failed to fetch chat sessions.";
      })
      // Handle fetchAnonymizedChatSessions action
      .addCase(fetchAnonymizedChatSessions.pending, (state) => {
        state.loadingTranscript = true;
        state.error = null;
        state.anonymizedChatSessions = [];
      })
      .addCase(
        fetchAnonymizedChatSessions.fulfilled,
        (state, action: PayloadAction<ChatSession[]>) => {
          state.loadingTranscript = false;
          state.anonymizedChatSessions = action.payload;
        }
      )
      .addCase(fetchAnonymizedChatSessions.rejected, (state, action) => {
        state.loadingTranscript = false;
        state.error =
          action.error.message || "Failed to fetch anonymized chat sessions.";
      })
      // Handle fetchTranscripts action
      .addCase(fetchTranscripts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.transcripts = [];
      })
      .addCase(
        fetchTranscripts.fulfilled,
        (state, action: PayloadAction<Transcript[]>) => {
          state.loading = false;
          state.transcripts = action.payload;
        }
      )
      .addCase(fetchTranscripts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch transcripts.";
      })
      // Handle fetchComments action
      .addCase(fetchComments.pending, (state) => {
        state.loadingComments = true;
        state.error = null;
        state.comments = [];
      })
      .addCase(
        fetchComments.fulfilled,
        (state, action: PayloadAction<Comment[]>) => {
          state.loadingComments = false;
          state.comments = action.payload;
        }
      )
      .addCase(fetchComments.rejected, (state, action) => {
        state.loadingComments = false;
        state.error = action.error.message || "Failed to fetch comments.";
      })
      // Handle addComment action
      .addCase(
        addComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          state.comments.push(action.payload);
        }
      )
      // Handle editComment action
      .addCase(
        editComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          const index = state.comments.findIndex(
            (comment) => comment.id === action.payload.id
          );
          if (index !== -1) {
            state.comments[index] = action.payload;
          }
        }
      )
      // Handle deleteComment action
      .addCase(
        deleteComment.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.comments = state.comments.filter(
            (comment) => comment.id !== action.payload
          );
        }
      );
  },
});

export default conversationSlice.reducer;
