import React from "react";
import { Box } from "@mui/material";
import { Comment } from "../../../redux/reducers/conversationsReducer";
import CommentComponent from "./CommentComponent";

interface ThreadComponentProps {
  comments: Comment[];
  conversationId: string;
}

const ThreadComponent: React.FC<ThreadComponentProps> = ({
  comments,
  conversationId,
}) => {
  // Sort comments within the thread in ascending order
  const sortedComments = [...comments].sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );

  const lastCommentId = sortedComments[sortedComments.length - 1]?.id;

  return (
    <Box
      sx={{
        position: "relative",
        mt: 1, // Increased margin top for spacing between threads
        ml: 1, // Indent the entire thread slightly
        mr: 1,
        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 10,
          bottom: 20,
          width: "2px",
          backgroundColor: "#ccc",
        },
      }}
    >
      {sortedComments.map((comment, index) => (
        <CommentComponent
          key={comment.id}
          comment={comment}
          conversationId={conversationId}
          isReplyable={comment.id === lastCommentId}
        />
      ))}
    </Box>
  );
};

export default ThreadComponent;
