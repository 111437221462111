import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";

// Only import logger in development mode
let logger: any;
if (process.env.NODE_ENV === 'development') {
  logger = require('redux-logger').default;
}

const store = configureStore({
  reducer: rootReducer,
  middleware(getDefaultMiddleware) {
    if (process.env.NODE_ENV === 'development' && logger) {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;