import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { RootState, AppDispatch } from "../../../redux/store";
import { updateSafetyIncidentStatus } from "../../../redux/actions/safetyIncidentsActions";
import {
  SafetyIncident,
  ReviewStatus,
} from "../../../redux/reducers/safetyIncidentsReducer";
import { useStytchUser } from "@stytch/react";

const SafetyIncidentView: React.FC = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useStytchUser();
  const incidents = useSelector(
    (state: RootState) => state.safetyIncidents.incidents
  );
  const incident = incidents.find(
    (incident: SafetyIncident) => incident.id === incidentId
  );
  useEffect(() => {
    if (incidents.length === 0 || !incident) {
      if (incidentId) {
        navigate(`/dashboard/safety-incidents?flag_id=${incidentId}`);
      } else {
        navigate(`/dashboard/safety-incidents`);
      }
    }
  }, [incidents, incidentId, incident, navigate]);

  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>(
    incident?.review_status || ReviewStatus.AWAITING_REVIEW
  );

  const handleStatusChange = (event: any) => {
    setReviewStatus(event.target.value as ReviewStatus);
  };

  const handleUpdateStatus = () => {
    if (incident) {
      dispatch(
        updateSafetyIncidentStatus({
          incident: incident,
          id: incident.id,
          review_status: reviewStatus,
          reviewer_user_id: user?.user?.user_id ?? "",
        })
      );
      navigate("/dashboard/safety-incidents");
    }
  };

  const handleZendeskLink = () => {
    if (incident?.zendesk_ticket_id) {
      const zendeskUrl = `https://personaai.zendesk.com/agent/tickets/${incident.zendesk_ticket_id}`;
      window.open(zendeskUrl, "_blank");
    }
  };

  if (!incident) {
    return <div>Incident not found</div>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        Incident Details
      </Typography>
      <TextField
        label="Incident ID"
        value={incident.id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Conversation ID"
        value={incident.conversation_id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Infringement Type"
        value={incident.infringement_class}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Infringement Subtype"
        value={incident.infringement_sub_class}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Severity"
        value={incident.infringement_class}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <FormControl style={{ marginBottom: "16px" }} fullWidth margin="normal">
        <InputLabel>Review Status</InputLabel>
        <div style={{ marginTop: "8px" }}>
          <Select value={reviewStatus} onChange={handleStatusChange}>
            <MenuItem value={ReviewStatus.AWAITING_REVIEW}>
              Awaiting Review
            </MenuItem>
            <MenuItem value={ReviewStatus.CONFIRMED_SAFE}>
              Confirmed Safe
            </MenuItem>
            <MenuItem value={ReviewStatus.REJECTED_UNSAFE}>
              Rejected Unsafe
            </MenuItem>
          </Select>
        </div>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleUpdateStatus}>
        Update Status
      </Button>
      {incident.zendesk_ticket_id && (
        <Button
          variant="contained"
          color="error"
          onClick={handleZendeskLink}
          style={{ marginLeft: "8px" }}
        >
          View Zendesk Ticket
        </Button>
      )}
      <Button
        variant="contained"
        color="warning"
        onClick={() => navigate("/dashboard/safety-incidents")}
        style={{ marginLeft: "8px" }}
      >
        Back
      </Button>
    </Box>
  );
};

export default SafetyIncidentView;
