import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { RootState, AppDispatch } from "../../../redux/store";
import { updateUserStatus } from "../../../redux/actions/userActions";
import { getFullNameFromUserName } from "../../../utils/render";
import { User, UserStatus } from "../../../redux/reducers/userReducer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SafetyIncidentsTab from "../SafetyIncidentsTab";
import ConversationsTab from "../ConversationsTab";

const UserView: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state.users.users);

  const user = useSelector((state: RootState) =>
    state.users.users.find((u: User) => u.id === userId)
  );

  useEffect(() => {
    // Check if users array is empty and navigate away if needed
    if (users.length === 0) {
      if (userId) {
        navigate(`/dashboard/users?user=${userId}`);
      } else {
        navigate(`/dashboard/users`);
      }
    }
  }, [users, userId, navigate]);

  const [open, setOpen] = useState(false);
  const [deactivationReason, setDeactivationReason] = useState("");

  const handleToggleActive = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeactivationReason("");
  };

  const handleAction = (status: UserStatus) => {
    dispatch(
      updateUserStatus({
        id: user?.id ?? "",
        status: status,
        deactivation_reason: deactivationReason,
        user: user,
      })
    )
      .then(() => {
        toast.success(`User status changed to ${status}`);
      })
      .catch((error) => {
        toast.error("Failed to change user status");
      });
    setOpen(false);
  };
  const {
    user: adminUser,
    loading: userLoading,
    error: userError,
  } = useSelector((state: RootState) => state.base);

  const canViewConversations = adminUser?.scopes?.includes(
    "conversationview:view"
  );
  const canViewSafetyIncidents = adminUser?.scopes?.includes(
    "safetyincidentsview:view"
  );

  if (!user || userLoading || userError) {
    return <div>User not found</div>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Typography variant="h5" gutterBottom>
        User Details
      </Typography>
      <TextField
        label="User Name"
        value={getFullNameFromUserName(user.name)}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="User ID"
        value={user.id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Stytch User ID"
        value={user.stytch_user_id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Phone Number"
        value={user.phone_number}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Email"
        value={user.email}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Status"
        value={user.status}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Environment"
        value={user.environment}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />

      {user.status !== UserStatus.ACTIVE && (
        <TextField
          color="warning"
          label="Deactivation Reason"
          value={user.deactivation_reason}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            icon={<CancelIcon sx={{ color: "red" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
            disabled
          />
        }
        label="Is Admin"
        checked={user.is_admin}
      />
      <FormControlLabel
        control={
          <Checkbox
            icon={<CancelIcon sx={{ color: "red" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
            disabled
          />
        }
        label="Phone Number Verified"
        checked={user.phone_number_verified}
      />
      <FormControlLabel
        control={
          <Checkbox
            icon={<CancelIcon sx={{ color: "red" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
            disabled
          />
        }
        label="Email Verified"
        checked={user.email_verified}
      />
      {canViewConversations ? (
        <ConversationsTab canReadConversation={true} userId={user.id} />
      ) : null}
      {canViewSafetyIncidents ? <SafetyIncidentsTab userId={user.id} /> : null}

      <Box display="flex" alignItems="center" marginTop={"16px"}>
        <Button variant="contained" color="error" onClick={handleToggleActive}>
          Change User Status
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => navigate("/dashboard/users")}
          style={{ marginLeft: "8px" }}
        >
          Back
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change User Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide an optional reason for
            suspending/banning/reactivating the user.
          </DialogContentText>
          <TextField
            autoFocus={user.status === UserStatus.ACTIVE}
            margin="dense"
            label="Reason for Suspension/Ban"
            type="text"
            fullWidth
            variant="standard"
            value={deactivationReason}
            onChange={(e) => setDeactivationReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {user.status !== UserStatus.ONBOARDING && (
            <Button
              onClick={() => handleAction(UserStatus.ONBOARDING)}
              color="info"
            >
              Re-Onboard User
            </Button>
          )}
          {user.status !== UserStatus.SUSPENDED && (
            <Button
              onClick={() => handleAction(UserStatus.SUSPENDED)}
              color="warning"
            >
              Suspend User
            </Button>
          )}
          {user.status !== UserStatus.BANNED && (
            <Button
              onClick={() => handleAction(UserStatus.BANNED)}
              color="error"
            >
              Ban User
            </Button>
          )}
          {user.status !== UserStatus.ACTIVE && (
            <Button
              onClick={() => handleAction(UserStatus.ACTIVE)}
              color="success"
            >
              Reactivate User
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserView;
