// actions.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
// Fetch Data
export const fetchData = createAsyncThunk("test/fetchData", async () => {
  const response = await api(
    "https://jsonplaceholder.typicode.com/posts/1",
    "GET"
  );
  return response;
});

// Post Data
export const postData = createAsyncThunk(
  "test/postData",
  async (newData: any) => {
    const response = await api(
      "https://jsonplaceholder.typicode.com/posts",
      "POST",
      newData
    );
    return response;
  }
);

// Put Data
export const putData = createAsyncThunk(
  "test/putData",
  async (updateData: any) => {
    const response = await api(`posts/${updateData.id}`, "PUT", updateData);
    return response;
  }
);

// Patch Data
export const patchData = createAsyncThunk(
  "test/patchData",
  async (updateData: any) => {
    const response = await api(
      `https://jsonplaceholder.typicode.com/posts/${updateData.id}`,
      "PATCH",
      updateData
    );
    return response;
  }
);
