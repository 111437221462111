import { ReactNode, useEffect, useRef } from "react";
import { useStytch, useStytchUser } from "@stytch/react";
import CryptoJS from "crypto-js";

const TokenAuthenticator = ({ children }: { children: ReactNode }) => {
  const stytch = useStytch();
  const { user } = useStytchUser();
  const hasCheckedTokenRef = useRef(false); // useRef to track token check status

  if (stytch.session?.getTokens()?.session_jwt) {
    const encryptedToken = CryptoJS.AES.encrypt(
      stytch.session?.getTokens()?.session_jwt ?? "",
      process.env.REACT_APP_CRYPTO_SECRET_KEY ?? ""
    ).toString();
    localStorage.setItem("jwt", encryptedToken);
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (stytch && !user && !hasCheckedTokenRef.current) {
      hasCheckedTokenRef.current = true;
      timeoutId = setTimeout(() => {
        hasCheckedTokenRef.current = false;
      }, 1500);
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      const tokenType = queryParams.get("stytch_token_type");

      // Perform token authentication if token and tokenType are present
      if (token && tokenType) {
        if (tokenType === "magic_links") {
          stytch.magicLinks.authenticate(token, {
            session_duration_minutes: 10080, // 7 days
          });
        } else if (tokenType === "oauth") {
          stytch.oauth.authenticate(token, {
            session_duration_minutes: 10080, // 7 days
          });
        }
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [stytch, user]); // Remove hasCheckedToken from dependencies

  // Ensure children are rendered only after initial token check
  if (!hasCheckedTokenRef.current) {
    return children;
  }

  return children;
};

export default TokenAuthenticator;
