import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";

export const fetchSafetyIncidents = createAsyncThunk(
  "safetyIncidents/fetchSafetyIncidents",
  async () => {
    const response = await api("v1/admin/safety-flags", "GET");
    return response;
  }
);

export interface UpdateSafetyIncidentStatusParams {
  reviewer_user_id: any;
  review_status: string;
  id: string;
  incident: any;
}

export const updateSafetyIncidentStatus = createAsyncThunk(
  "safetyIncidents/updateSafetyIncidentStatus",
  async (incidentParams: UpdateSafetyIncidentStatusParams) => {
    const response = await api(
      `v1/admin/safety-flags/${incidentParams.id}`,
      "PUT",
      {
        ...incidentParams.incident,
        review_status: incidentParams.review_status,
        reviewer_user_id: incidentParams.reviewer_user_id,
      }
    );
    return response;
  }
);
