import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import TokenAuthenticator from "./auth/tokenAuthenticator";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";

const stytch = new StytchUIClient(
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "test key"
);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    {/* StytchProvider gives our app access to the Stytch client */}
    <StytchProvider stytch={stytch}>
      <TokenAuthenticator>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </TokenAuthenticator>
    </StytchProvider>
  </React.StrictMode>
);
