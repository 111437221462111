import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { fetchUser } from "../actions/baseActions";
import { User } from "./userReducer";

interface BaseState {
  loading: boolean;
  error: string | null;
  user: User | null;
}

const initialState: BaseState = {
  loading: true,
  error: null,
  user: null,
};

const baseSlice = createSlice({
  name: "base",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<BaseState>) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch user data";
      });
  },
});

export const { setLoading, setError, setUser } = baseSlice.actions;
export default baseSlice.reducer;
