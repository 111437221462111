import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {
  fetchSafetyIncidents,
  updateSafetyIncidentStatus,
} from "../actions/safetyIncidentsActions";

export interface SafetyIncident {
  id: string;
  description: string;
  status: string;
  flagged_at: string;
  user_id: string;
  conversation_id: string;
  created_at: string;
  blob_path: string | null;
  review_status: ReviewStatus;
  reviewer_user_id: string | null;
  infringement_class: InfringementType;
  infringement_sub_class: InfringementSubType;
  severity: number;
  zendesk_ticket_id: string | null;
}

export enum ReviewStatus {
  AWAITING_REVIEW = "AWAITING_REVIEW",
  CONFIRMED_SAFE = "CONFIRMED_SAFE",
  REJECTED_UNSAFE = "REJECTED_UNSAFE",
}

export enum InfringementType {
  INAPPROPRIATE = "inappropriate",
  AT_RISK = "at_risk",
}

export enum InfringementSubType {
  CSAM = "csam",
  SEXUAL_CONTENT = "sexual_content",
  SELF_HARM = "self_harm",
  VIOLENCE = "violence",
  HATE_SPEECH = "hate_speech",
}

interface SafetyIncidentsState {
  incidents: SafetyIncident[];
  loading: boolean;
  error: string | null;
}

const initialState: SafetyIncidentsState = {
  incidents: [],
  loading: false,
  error: null,
};

const safetyIncidentSlice = createSlice({
  name: "safetyIncidents",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SafetyIncidentsState>) => {
    builder
      .addCase(fetchSafetyIncidents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSafetyIncidents.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.incidents = action.payload;
        }
      )
      .addCase(fetchSafetyIncidents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch data";
      })
      .addCase(
        updateSafetyIncidentStatus.fulfilled,
        (state, action: PayloadAction<SafetyIncident>) => {
          const index = state.incidents.findIndex(
            (incident) => incident.id === action.payload.id
          );
          if (index !== -1) {
            state.incidents[index] = action.payload;
          }
        }
      );
  },
});

export default safetyIncidentSlice.reducer;
