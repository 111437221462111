import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { v4 as uuidv4 } from "uuid";

export const fetchConversations = createAsyncThunk(
  "conversations/fetchConversations",
  async () => {
    const response = await api("v1/admin/conversations", "GET");
    return response;
  }
);

export const fetchTranscripts = createAsyncThunk(
  "conversations/fetchTranscripts",
  async () => {
    const response = await api("v1/admin/transcripts", "GET");
    return response;
  }
);

export const fetchChatSessions = createAsyncThunk(
  "conversations/fetchChatSessions",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/chat-sessions/${conversationId}`,
      "GET"
    );
    return response;
  }
);

export const fetchAnonymizedChatSessions = createAsyncThunk(
  "conversations/fetchAnonymizedChatSessions",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/anonymized-chat-sessions/${conversationId}`,
      "GET"
    );
    return response;
  }
);

// New Actions for Comments

export const fetchComments = createAsyncThunk(
  "conversations/fetchComments",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/annotations/${conversationId}/comments`,
      "GET"
    );
    return response;
  }
);

export const addComment = createAsyncThunk(
  "conversations/addComment",
  async (payload: {
    conversationId: string;
    targetId?: string;
    comment: string;
    threadId?: string;
  }) => {
    // Generate a unique UUID if threadId is not provided
    const threadId = payload.threadId || uuidv4();

    const response = await api(
      `v1/admin/annotations/${payload.conversationId}/comment`,
      "POST",
      {
        target_id: payload.targetId,
        comment: payload.comment,
        thread_id: threadId,
      }
    );
    return response;
  }
);

export const editComment = createAsyncThunk(
  "conversations/editComment",
  async (payload: {
    conversationId: string;
    commentId: string;
    comment: string;
  }) => {
    const response = await api(
      `v1/admin/annotations/${payload.conversationId}/comment/${payload.commentId}`,
      "PUT",
      {
        comment: payload.comment,
      }
    );
    return response;
  }
);

export const deleteComment = createAsyncThunk(
  "conversations/deleteComment",
  async (payload: { conversationId: string; commentId: string }) => {
    await api(
      `v1/admin/annotations/${payload.conversationId}/comment/${payload.commentId}`,
      "DELETE"
    );
    return payload.commentId;
  }
);
