import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Fab,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { format } from "date-fns";
import {
  fetchAnonymizedChatSessions,
  fetchChatSessions,
  fetchComments,
} from "../../../redux/actions/conversationsActions";
import MessageBubble from "./MessageBubble";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Message } from "../../../redux/reducers/conversationsReducer";
import { getFullNameFromUserName, getTargetId } from "../../../utils/render";

interface ConversationsViewProps {
  canReadConversation?: boolean;
}

const ConversationView: React.FC<ConversationsViewProps> = ({
  canReadConversation,
}) => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showRealData, setShowRealData] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
  const [blurEffect, setBlurEffect] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [anonMessages, setAnonMessages] = useState<Message[]>([]);
  const [commenters, setCommenters] = useState<
    { id: string; full_name: string }[]
  >([]);
  const [hiddenCommenters, setHiddenCommenters] = useState<Set<string>>(
    new Set()
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetId = searchParams.get("target_id"); // Get the target_id from the

  // scroll to specific message
  useEffect(() => {
    if (targetId) {
      let attemptCount = 0;
      const intervalId = setInterval(() => {
        const messageElement = document.getElementById(targetId);
        if (messageElement) {
          messageElement.scrollIntoView({ behavior: "smooth" });
          clearInterval(intervalId); // Stop the interval after the element is found or after 5 tries
        }
        if (attemptCount > 100) {
          clearInterval(intervalId);
        }
        attemptCount++;
      }, 200); // Check every 1000 milliseconds (1 second)

      // Clean up the interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [targetId]);

  const conversation = useSelector((state: RootState) =>
    state.conversations?.conversations?.find((c) => c.id === conversationId)
  );

  const transcript = useSelector((state: RootState) =>
    state.conversations?.transcripts?.find(
      (c) => c.conversation_id === conversationId
    )
  );

  const { user: adminUser } = useSelector((state: RootState) => state.base);

  const canViewUser = adminUser?.scopes?.includes("userview:view");

  const comments = useSelector(
    (state: RootState) => state.conversations.comments
  );

  useEffect(() => {
    const uniqueCommenterMap = new Map<
      string,
      { id: string; full_name: string }
    >();
    comments.forEach((comment) => {
      if (!uniqueCommenterMap.has(comment.user.id)) {
        uniqueCommenterMap.set(comment.user.id, {
          id: comment.user.id,
          full_name: getFullNameFromUserName(comment.user.name) || "Unknown", // Adjust based on your data structure
        });
      }
    });
    const uniqueCommenters = Array.from(uniqueCommenterMap.values());
    setCommenters(uniqueCommenters);
  }, [comments]);

  const getCommentsForMessage = (targetId: string) => {
    return comments
      .filter(
        (comment) => comment.target_id === targetId && !comment.deleted_at
      )
      .filter((comment) => !hiddenCommenters.has(comment.user.id));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const targetId = queryParams.get("target_id"); // Extract target_id

    // Check if conversation exists
    if (!conversation && canReadConversation) {
      if (conversationId) {
        // Include target_id in the navigation if it exists
        const url = targetId
          ? `/dashboard/conversations?conversation_id=${conversationId}&target_id=${targetId}`
          : `/dashboard/conversations?conversation_id=${conversationId}`;
        navigate(url);
      } else {
        navigate("/dashboard/conversations");
      }
    }

    if (!transcript && !canReadConversation) {
      if (conversationId) {
        // Include target_id in the navigation if it exists
        const url = targetId
          ? `/dashboard/conversations?conversation_id=${conversationId}&target_id=${targetId}`
          : `/dashboard/conversations?conversation_id=${conversationId}`;
        navigate(url);
      } else {
        navigate("/dashboard/conversations");
      }
    }
  }, [
    conversation,
    conversationId,
    navigate,
    transcript,
    canReadConversation,
    location.search,
  ]);

  useEffect(() => {
    if (conversationId) {
      dispatch(fetchComments(conversationId));
    }
  }, [conversationId, dispatch]);

  useEffect(() => {
    if (
      conversation?.id &&
      conversation.is_private === false &&
      canReadConversation
    ) {
      dispatch(fetchChatSessions(conversation?.id));
    }
    if (transcript?.conversation_id && transcript?.is_private === false) {
      dispatch(fetchAnonymizedChatSessions(transcript?.conversation_id));
    } else if (conversation?.id && conversation?.is_private === false) {
      dispatch(fetchAnonymizedChatSessions(conversation?.id));
    }
  }, [
    transcript?.conversation_id,
    transcript?.is_private,
    conversation?.id,
    conversation?.is_private,
    dispatch,
    canReadConversation,
  ]);
  const anonymizedChatSessions = useSelector(
    (state: RootState) => state.conversations.anonymizedChatSessions
  );

  const chatSessions = useSelector(
    (state: RootState) => state.conversations.chatSessions
  );

  const loading = useSelector(
    (state: RootState) => state.conversations?.loading
  );
  const loadingConversation = useSelector(
    (state: RootState) => state.conversations?.loadingConversation
  );

  const loadingTranscript = useSelector(
    (state: RootState) => state.conversations?.loadingTranscript
  );

  const loadingComments = useSelector(
    (state: RootState) => state.conversations.loadingComments
  );

  useEffect(() => {
    // Organize messages by date
    if (conversationId) {
      const anonAllMessages: Message[] = anonymizedChatSessions.flatMap(
        (session) =>
          session.messages.map((message) => ({
            ...message,
            chat_session_id: session.id,
          }))
      );

      const allMessages: Message[] = chatSessions.flatMap((session) =>
        session.messages.map((message) => ({
          ...message,
          chat_session_id: session.id,
        }))
      );

      const sortedMessages = allMessages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
      const sortedAnonMessages = anonAllMessages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

      const datesSet = new Set<string>();
      const messagesByDate: Record<string, Message[]> = {};
      if (canReadConversation && showRealData) {
        sortedMessages.forEach((message) => {
          const date = format(new Date(message.created_at), "MMMM dd, yyyy");
          datesSet.add(date);
          if (!messagesByDate[date]) {
            messagesByDate[date] = [];
          }
          messagesByDate[date].push(message);
        });
      } else {
        sortedAnonMessages.forEach((message) => {
          const date = format(new Date(message.created_at), "MMMM dd, yyyy");
          datesSet.add(date);
          if (!messagesByDate[date]) {
            messagesByDate[date] = [];
          }
          messagesByDate[date].push(message);
        });
      }
      const sortedDates = Array.from(datesSet).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
      setMessages(sortedMessages);
      setDates(sortedDates);
      setAnonMessages(sortedAnonMessages);
    }
  }, [
    chatSessions,
    conversationId,
    anonymizedChatSessions,
    canReadConversation,
    showRealData,
  ]);

  const handleViewUser = (userId: string | null) => {
    if (userId) {
      navigate(`/dashboard/users?user=${userId}`);
    }
  };

  const scrollToDate = (date: string) => {
    const element = document.getElementById(date);
    if (element) {
      setBlurEffect(true);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedDate(date);
      setTimeout(() => setBlurEffect(false), 500); // Remove blur after 0.5 seconds
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      if (chatContainerRef.current.scrollTop > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }
  };

  const scrollToTop = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const containerRef = chatContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef) {
        containerRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  if (
    (canReadConversation && !conversation) ||
    (!canReadConversation && !transcript)
  ) {
    return <div>Conversation not found</div>;
  }

  const handleToggleView = () => {
    setShowRealData((prev) => !prev);
  };

  const renderMessages = () => {
    const messagesToUse =
      showRealData && canReadConversation ? messages : anonMessages;
    if (conversation?.is_private)
      return <div>This is a private conversation</div>;
    if (messagesToUse.length === 0) return <div>No messages found</div>;

    return dates.map((date) => (
      <Box key={date} id={date} sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom textAlign="center">
          {date}
        </Typography>
        {messagesToUse
          .filter(
            (message) =>
              format(new Date(message.created_at), "MMMM dd, yyyy") === date
          )
          .map((message, index) => {
            const targetId = getTargetId(
              showRealData && canReadConversation
                ? message.id
                : message.chat_message_id,
              message.chat_session_id
            );
            const messageComments = getCommentsForMessage(targetId);
            return (
              <MessageBubble
                key={index}
                message={message}
                conversationId={conversationId}
                isRightAligned={message.type !== "human"}
                canViewAllData={showRealData && canReadConversation}
                comments={messageComments}
                chatSessionId={message.chat_session_id}
              />
            );
          })}
      </Box>
    ));
  };

  const renderDetails = () => {
    if (!canReadConversation || !showRealData) {
      return (
        <>
          <Typography variant="subtitle1">
            <strong>Human Initial:</strong>{" "}
            {transcript?.human_initial || conversation?.human?.full_name?.[0]}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Bot Name:</strong>{" "}
            {transcript?.bot_name || conversation?.bot?.name}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Conversation ID:</strong>{" "}
            {transcript?.conversation_id || conversation?.id}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Created At:</strong>{" "}
            {(transcript || conversation) &&
              format(
                new Date(
                  transcript?.created_at || conversation?.created_at || ""
                ),
                "yyyy-MM-dd HH:mm:ss"
              )}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Environment:</strong>{" "}
            {transcript?.environment || conversation?.environment}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle1">
          <strong>Human:</strong> {conversation?.human.first_name} (ID:{" "}
          {conversation?.human.id})
        </Typography>
        <Typography variant="subtitle1">
          <strong>Bot:</strong> {conversation?.bot.name} (ID:{" "}
          {conversation?.bot.id})
        </Typography>
        <Typography variant="subtitle1">
          <strong>Conversation ID:</strong> {conversation?.id}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Created At:</strong>{" "}
          {format(
            new Date(conversation?.created_at || ""),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Private:</strong> {conversation?.is_private ? "Yes" : "No"}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Environment:</strong> {conversation?.environment}
        </Typography>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Messages Container */}
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          padding: "20px",
          minWidth: "70%",
          transition: "filter 0.3s",
          filter: blurEffect ? "blur(4px)" : "none",
          position: "relative",
        }}
        ref={chatContainerRef}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Conversation Details
          </Typography>
          {renderDetails()}
          {commenters.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">
                <strong>Annotators:</strong>
              </Typography>
              <Box mt={1} display="flex" flexWrap="wrap">
                {commenters.map((user) => (
                  <Box
                    key={user.id}
                    sx={{
                      display: "inline-block",
                      padding: "4px 8px",
                      margin: "4px",
                      backgroundColor: hiddenCommenters.has(user.id)
                        ? "grey"
                        : "primary.main",
                      color: "white",
                      borderRadius: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // Toggle hidden commenters
                      setHiddenCommenters((prev) => {
                        const newSet = new Set(prev);
                        if (newSet.has(user.id)) {
                          newSet.delete(user.id);
                        } else {
                          newSet.add(user.id);
                        }
                        return newSet;
                      });
                    }}
                  >
                    {user.full_name}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box mt={4} display="flex" alignItems="center">
            {conversation?.owning_user_id && canViewUser && (
              <Button
                variant="contained"
                color="warning"
                onClick={() =>
                  handleViewUser(conversation.owning_user_id ?? "")
                }
              >
                View Conversation User
              </Button>
            )}
            {canReadConversation && (
              <Button
                variant="contained"
                color="warning"
                onClick={() => handleToggleView()}
                sx={{ marginLeft: conversation?.owning_user_id ? 2 : 0 }}
              >
                {showRealData ? "View Anonymized Data" : "View Real Data"}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/dashboard/conversations")}
              sx={{
                marginLeft:
                  conversation?.owning_user_id || canReadConversation ? 2 : 0,
              }}
            >
              Back
            </Button>
          </Box>
        </Box>

        {/* Render Messages */}
        {loading ||
        loadingTranscript ||
        loadingConversation ||
        loadingComments ? (
          <Box mt={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          renderMessages()
        )}
      </Box>

      {/* Date Selector Box */}
      {!conversation?.is_private && (
        <Box
          sx={{
            width: "20%",
            borderLeft: "1px solid #ccc",
            backgroundColor: "#f0f0f0",
            padding: "20px",
            minWidth: "200px",
            maxHeight: "100vh",
            overflowY: "auto",
            borderRadius: "16px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Select a Date
          </Typography>
          <List>
            {dates.map((date, index) => (
              <ListItem
                button
                key={index}
                selected={selectedDate === date}
                onClick={() => scrollToDate(date)}
              >
                <ListItemText primary={date} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {showScrollToTop && (
        <Fab
          color="primary"
          size="small"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </Box>
  );
};

export default ConversationView;
