// api.ts
import CryptoJS from "crypto-js";

export const api = async (
  url_extension: string,
  method: string,
  data?: any
) => {
  const encryptedJwt = localStorage.getItem("jwt");
  let jwt = null;

  if (encryptedJwt) {
    const bytes = CryptoJS.AES.decrypt(
      encryptedJwt,
      process.env.REACT_APP_CRYPTO_SECRET_KEY ?? ""
    );
    jwt = bytes.toString(CryptoJS.enc.Utf8);
  }
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (jwt) {
    headers["Authorization"] = `Bearer ${jwt}`;
  }

  const response = await fetch(
    process.env.REACT_APP_BASE_BACKEND_URL + url_extension,
    {
      method,
      headers,
      body: data ? JSON.stringify(data) : undefined,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};
