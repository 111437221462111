import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  editComment,
  deleteComment,
  addComment,
} from "../../../redux/actions/conversationsActions";
import { Comment } from "../../../redux/reducers/conversationsReducer";
import { format } from "date-fns";
import { getFullNameFromUserName } from "../../../utils/render";

interface CommentComponentProps {
  comment: Comment;
  conversationId: string;
  isReplyable: boolean;
}

const CommentComponent: React.FC<CommentComponentProps> = ({
  comment,
  conversationId,
  isReplyable,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.base.user);
  const isAuthor = currentUser?.id === comment.user.id;

  const [isEditing, setIsEditing] = useState(false);
  const [editedCommentText, setEditedCommentText] = useState(comment.comment);
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEditComment = () => {
    if (editedCommentText.trim() !== "") {
      dispatch(
        editComment({
          conversationId: conversationId,
          commentId: comment.id,
          comment: editedCommentText,
        })
      );
      setIsEditing(false);
    }
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const commentTarget = `cmt:${comment.id}`;

  const handleShareComment = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_LOGIN_REDIRECT_URL}/conversations/${conversationId}?target_id=${commentTarget}`
    );
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  const handleDeleteComment = () => {
    dispatch(
      deleteComment({
        conversationId: conversationId,
        commentId: comment.id,
      })
    );
    setShowDeleteModal(false);
  };

  const handleAddReply = () => {
    if (replyText.trim() !== "") {
      dispatch(
        addComment({
          conversationId: conversationId,
          targetId: comment.target_id,
          comment: replyText,
          threadId: comment.thread_id, // Use the same thread_id for replies
        })
      );
      setReplyText("");
      setIsReplying(false);
    }
  };

  return (
    <Box
      id={commentTarget}
      sx={{
        backgroundColor: "#fff", // White card
        p: 2,
        borderRadius: 2,
        mb: 2,
        maxWidth: "100%",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Box shadow for card effect
        position: "relative",
      }}
    >
      <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
        {getFullNameFromUserName(comment.user.name)}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
          fontSize: 12,
          color: "gray",
        }}
      >
        {comment.updated_at ? (
          <>
            {format(new Date(comment.updated_at), "MMM d, yyyy HH:mm")} (edited)
          </>
        ) : (
          format(new Date(comment.created_at), "MMM d, yyyy HH:mm")
        )}
      </Typography>
      {!isEditing ? (
        <Typography variant="body2" sx={{ mt: 1 }}>
          {comment.comment}
        </Typography>
      ) : (
        <TextField
          fullWidth
          multiline
          rows={2}
          value={editedCommentText}
          onChange={(e) => setEditedCommentText(e.target.value)}
          sx={{ mt: 1 }}
        />
      )}
      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
        {isAuthor && (
          <>
            {!isEditing ? (
              <>
                <IconButton size="small" onClick={() => setIsEditing(true)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleEditComment}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        )}
        {!isEditing && isReplyable && (
          <Button
            size="small"
            startIcon={<ReplyIcon />}
            onClick={() => setIsReplying(!isReplying)}
          >
            Reply
          </Button>
        )}
        {/* Add this Box with ml: 'auto' to push the share icon to the right */}
        <Box sx={{ ml: "auto" }}>
          <Tooltip
            title={showTooltip ? "Copied to clipboard" : "Share comment"}
            open={showTooltip}
            onClose={() => setShowTooltip(false)}
          >
            <IconButton size="small" onClick={handleShareComment}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {isReplying && (
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            sx={{ mb: 1 }}
          />
          <Button size="small" onClick={handleAddReply}>
            Submit Reply
          </Button>
        </Box>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Confirm Deletion
          </Typography>
          <Typography
            id="delete-confirmation-description"
            sx={{ mb: 3 }}
            variant="body1"
          >
            Are you sure you want to delete this comment?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteComment}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CommentComponent;
