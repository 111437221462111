import { UserName } from "../redux/reducers/userReducer";

export const getFullNameFromUserName = (name?: UserName): string => {
  if (!name) return "";

  const { first_name = "", middle_name = "", last_name = "" } = name;
  return [first_name, middle_name, last_name].filter(Boolean).join(" ");
};

export const getTargetId = (
  messageId: number,
  chatSessionId: string
): string => {
  return `msg:${chatSessionId}:${messageId.toString()}`;
};
