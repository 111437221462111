import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { fetchUsers, updateUserStatus } from "../actions/userActions";

export interface User {
  id: string;
  email: string;
  name?: UserName;
  is_admin: boolean;
  phone_number_verified: boolean;
  email_verified?: boolean;
  is_active?: boolean; // DEPRECATED
  is_waitlisted?: boolean; // DEPRECATED
  stytch_user_id?: string;
  phone_number?: string;
  deactivation_reason?: string;
  is_developer: boolean;
  status: UserStatus;
  environment: string;
  scopes?: string[];
  roles?: UserRole[];
}

export interface UserName {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}

export enum UserStatus {
  ACTIVE = "active",
  WAITLISTED = "waitlisted",
  SUSPENDED = "suspended",
  BANNED = "banned",
  ONBOARDING = "onboarding",
}

export enum UserRole {
  ADMIN = "admin",
  USER = "user",
  ANNOTATOR = "annotator",
}

interface UsersState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState: UsersState = {
  users: [],
  loading: false,
  error: null,
};

const testSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UsersState>) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch data";
      })
      .addCase(
        updateUserStatus.fulfilled,
        (state, action: PayloadAction<User>) => {
          const index = state.users.findIndex(
            (user) => user.id === action.payload.id
          );
          if (index !== -1) {
            state.users[index] = action.payload;
          }
        }
      );
  },
});

export default testSlice.reducer;
