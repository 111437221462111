import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { User, UserStatus } from "../reducers/userReducer";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await api("v1/admin/users", "GET");
  return response;
});

interface UpdateUserStatusParams {
  id: string;
  status: UserStatus;
  deactivation_reason?: string;
  user?: User;
}

export const updateUserStatus = createAsyncThunk(
  "users/updateUserStatus",
  async ({ id, status, deactivation_reason, user }: UpdateUserStatusParams) => {
    const response = await api(`v1/admin/users/${id}`, "PUT", {
      ...user,
      status,
      deactivation_reason,
    });
    return response;
  }
);

export const fetchComments = createAsyncThunk(
  "conversations/fetchComments",
  async (conversationId: string) => {
    const response = await api(
      `v1/admin/annotations/${conversationId}/comments`,
      "GET"
    );
    return response;
  }
);

export const addComment = createAsyncThunk(
  "conversations/addComment",
  async (payload: {
    conversationId: string;
    messageId?: string;
    comment: string;
    threadId?: string;
  }) => {
    const response = await api(
      `v1/admin/annotations/${payload.conversationId}/comment`,
      "POST",
      {
        message_id: payload.messageId,
        comment: payload.comment,
        thread_id: payload.threadId,
      }
    );
    return response;
  }
);

export const editComment = createAsyncThunk(
  "conversations/editComment",
  async (payload: {
    conversationId: string;
    commentId: string;
    comment: string;
  }) => {
    const response = await api(
      `v1/admin/annotations/${payload.conversationId}/comment/${payload.commentId}`,
      "PUT",
      {
        comment: payload.comment,
      }
    );
    return response;
  }
);

export const deleteComment = createAsyncThunk(
  "conversations/deleteComment",
  async (payload: { conversationId: string; commentId: string }) => {
    await api(
      `v1/admin/annotations/${payload.conversationId}/comment/${payload.commentId}`,
      "DELETE"
    );
    return payload.commentId;
  }
);
