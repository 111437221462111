import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Link,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConversations,
  fetchTranscripts,
} from "../../redux/actions/conversationsActions";
import { RootState, AppDispatch } from "../../redux/store";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { debounce } from "lodash";
import {
  Conversation,
  Transcript,
} from "../../redux/reducers/conversationsReducer";

interface ConversationsTabProps {
  userId?: string;
  canReadConversation?: boolean;
}

const ConversationsTab: React.FC<ConversationsTabProps> = ({
  userId,
  canReadConversation,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { conversations, transcripts, loading, error } = useSelector(
    (state: RootState) => state.conversations
  );
  const navigate = useNavigate();

  // Filter states
  const [conversationIdFilter, setConversationIdFilter] = useState<string>("");
  const [environmentFilter, setEnvironmentFilter] = useState<string>("");
  const [removePrivateFilter, setRemovePrivateFilter] =
    useState<boolean>(false);
  const [minTurnsFilter, setMinTurnsFilter] = useState<number | null>(null);

  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const debouncedFetchConversations = useMemo(
    () => debounce(() => dispatch(fetchConversations()), 300),
    [dispatch]
  );
  const debouncedFetchTranscripts = useMemo(
    () => debounce(() => dispatch(fetchTranscripts()), 300),
    [dispatch]
  );

  useEffect(() => {
    if (!canReadConversation) {
      debouncedFetchTranscripts();
    } else {
      debouncedFetchConversations();
    }

    // Clean up the debounced functions on unmount
    return () => {
      debouncedFetchConversations.cancel();
      debouncedFetchTranscripts.cancel();
    };
  }, [
    debouncedFetchConversations,
    debouncedFetchTranscripts,
    canReadConversation,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const conversationId = queryParams.get("conversation_id");
    const targetId = queryParams.get("target_id"); // Extract the target_id

    if (conversationId && filteredRows.length > 0) {
      // Include target_id in the navigation if it exists
      const url = targetId
        ? `/dashboard/conversations/${conversationId}?target_id=${targetId}`
        : `/dashboard/conversations/${conversationId}`;
      navigate(url);
    }
  }, [filteredRows, location.search, navigate]);

  useEffect(() => {
    let filteredData: (Conversation | Transcript)[] = canReadConversation
      ? conversations
      : transcripts;

    if (userId && canReadConversation) {
      filteredData = filteredData.filter(
        (item: any) => item.owning_user_id === userId
      );
    }

    // Apply filters
    if (conversationIdFilter) {
      filteredData = filteredData.filter(
        (item: any) =>
          item.id?.includes(conversationIdFilter) ||
          item.conversation_id?.includes(conversationIdFilter)
      );
    }

    if (environmentFilter) {
      filteredData = filteredData.filter(
        (item: any) => item.environment === environmentFilter
      );
    }

    if (removePrivateFilter) {
      filteredData = filteredData.filter((item: any) => !item.is_private);
    }

    if (minTurnsFilter !== null) {
      filteredData = filteredData.filter(
        (item: any) =>
          item.num_turns_in_latest_session >= (minTurnsFilter as number)
      );
    }

    setFilteredRows(filteredData);
  }, [
    conversations,
    transcripts,
    userId,
    canReadConversation,
    conversationIdFilter,
    environmentFilter,
    removePrivateFilter,
    minTurnsFilter,
  ]);

  const handleViewConversation = (id: string) => {
    navigate(`/dashboard/conversations/${id}`);
  };

  const renderBooleanCell = (params: any) => (
    <p style={{ textAlign: "center", margin: 0 }}>
      {params.value ? "✔️" : "❌"}
    </p>
  );

  const columns: GridColDef[] = canReadConversation
    ? [
        {
          field: "id",
          headerName: "Conversation ID",
          flex: 0.5,
          minWidth: 130,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "owning_user_id",
          headerName: "User ID",
          flex: 0.5,
          minWidth: 100,
          headerAlign: "center",
          align: "center",
          renderCell: (params: any) =>
            params.value ? (
              <Link
                href={`/dashboard/users?user=${params.value}`}
                style={{ textDecoration: "none", color: "blue" }}
              >
                {params.value}
              </Link>
            ) : null,
        },
        {
          field: "created_at",
          headerName: "Created At",
          flex: 1,
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          valueGetter: (params: any) => {
            return params
              ? format(new Date(params), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          field: "latest_chat_message_time",
          headerName: "Last Chat Message",
          flex: 1,
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          valueGetter: (params: any) => {
            return params
              ? format(new Date(params), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          field: "num_turns_in_latest_session",
          headerName: "Last Chat Turns",
          flex: 0.5,
          minWidth: 120,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "is_private",
          headerName: "Is Private?",
          flex: 0.5,
          renderCell: renderBooleanCell,
          minWidth: 100,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "environment",
          headerName: "Environment",
          flex: 0.5,
          minWidth: 100,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "actions",
          headerName: "",
          flex: 0.5,
          minWidth: 150,
          headerAlign: "center",
          renderCell: (params: any) => (
            <p style={{ textAlign: "center", margin: 0 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleViewConversation(params.row.id)}
              >
                View
              </Button>
            </p>
          ),
        },
      ]
    : [
        {
          field: "conversation_id",
          headerName: "Conversation ID",
          flex: 1,
          minWidth: 130,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "created_at",
          headerName: "Created At",
          flex: 1,
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          valueGetter: (params: any) => {
            return params
              ? format(new Date(params), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          field: "human_initial",
          headerName: "Human Initial",
          flex: 0.33,
          minWidth: 50,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "latest_chat_message_time",
          headerName: "Last Chat Message",
          flex: 1,
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          valueGetter: (params: any) => {
            return params
              ? format(new Date(params), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          field: "num_turns_in_latest_session",
          headerName: "Last Chat Turns",
          flex: 0.5,
          minWidth: 120,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "actions",
          headerName: "",
          flex: 0.5,
          minWidth: 150,
          headerAlign: "center",
          renderCell: (params: any) => (
            <p style={{ textAlign: "center", margin: 0 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleViewConversation(params.row.conversation_id)
                }
              >
                View
              </Button>
            </p>
          ),
        },
      ];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mt: 2,
          width: "100%",
        }}
      >
        {canReadConversation && !userId ? (
          <>
            {/* Conversation ID Filter */}
            <TextField
              label="Conversation ID"
              variant="outlined"
              value={conversationIdFilter}
              onChange={(e) => setConversationIdFilter(e.target.value)}
              sx={{ flex: 1 }}
            />

            {/* Environment Filter */}
            <FormControl variant="outlined" sx={{ minWidth: 150 }}>
              <InputLabel>Environment</InputLabel>
              <Select
                value={environmentFilter}
                onChange={(e) => setEnvironmentFilter(e.target.value)}
                label="Environment"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="qa">QA</MenuItem>
                <MenuItem value="staging">Staging</MenuItem>
                <MenuItem value="production">Production</MenuItem>
                {/* Add other environments as needed */}
              </Select>
            </FormControl>

            {/* Minimum Turns Filter */}
            <TextField
              label="Min Turns in Last Chat"
              variant="outlined"
              type="number"
              value={minTurnsFilter !== null ? minTurnsFilter : ""}
              onChange={(e) =>
                setMinTurnsFilter(
                  e.target.value ? parseInt(e.target.value, 10) : null
                )
              }
              sx={{ width: "200px" }}
            />
            {/* Remove Private Filter */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={removePrivateFilter}
                  onChange={(e) => setRemovePrivateFilter(e.target.checked)}
                />
              }
              label="Remove Private"
            />
          </>
        ) : null}
        {!canReadConversation && !userId && (
          <>
            {/* Conversation ID Filter */}
            <TextField
              label="Conversation ID"
              variant="outlined"
              value={conversationIdFilter}
              onChange={(e) => setConversationIdFilter(e.target.value)}
              sx={{ flex: 1 }}
            />

            {/* Additional filters can be added here for non-admin users */}
          </>
        )}
        {!userId && (
          <Button
            variant="outlined"
            onClick={() => {
              setRemovePrivateFilter(false);
              setMinTurnsFilter(null);
              setEnvironmentFilter("");
              setConversationIdFilter(""); // Reset status filter
            }}
          >
            Reset Filters
          </Button>
        )}
      </Box>

      {userId && (
        <Box sx={{ paddingBottom: "16px" }}>
          <Typography variant="h6" gutterBottom>
            User Conversations
          </Typography>
        </Box>
      )}
      <Box sx={{ height: userId ? "30vh" : "65vh", width: "100%", mt: 2 }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          getRowId={(row) => row.id || row.conversation_id}
        />
      </Box>
    </>
  );
};

export default ConversationsTab;
