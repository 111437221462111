import { Box, Typography } from "@mui/material";
import { Lock as LockIcon } from "@mui/icons-material";

const AccessDenied: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      mt: 10,
    }}
  >
    <LockIcon sx={{ fontSize: 80, mb: 2 }} color="error" />
    <Typography variant="h4" color="error">
      Access Denied
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      We're sorry, but you do not have permission to view this page.
    </Typography>
  </Box>
);

export default AccessDenied;
