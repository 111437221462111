import React, { useEffect, useMemo } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Chip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSafetyIncidents } from "../../redux/actions/safetyIncidentsActions";
import { RootState, AppDispatch } from "../../redux/store";
import { ReviewStatus } from "../../redux/reducers/safetyIncidentsReducer"; // Import ReviewStatus enum
import { debounce } from "lodash";

interface SafetyIncidentsTabProps {
  userId?: string;
}

const SafetyIncidentsTab: React.FC<SafetyIncidentsTabProps> = ({ userId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    incidents: allIncidents,
    loading,
    error,
  } = useSelector((state: RootState) => state.safetyIncidents);

  const debouncedFetchSafetyIncidents = useMemo(
    () => debounce(() => dispatch(fetchSafetyIncidents()), 300),
    [dispatch]
  );

  useEffect(() => {
    debouncedFetchSafetyIncidents();

    // Clean up the debounced function on unmount
    return () => {
      debouncedFetchSafetyIncidents.cancel();
    };
  }, [debouncedFetchSafetyIncidents]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const flagId = queryParams.get("flag_id");

    if (!userId && flagId && allIncidents.length > 0) {
      navigate(`/dashboard/safety-incidents/${flagId}`);
    }
  }, [allIncidents, location.search, navigate, userId]);

  const handleViewIncident = (id: string) => {
    navigate(`/dashboard/safety-incidents/${id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Incident ID",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "conversation_id",
      headerName: "Conversation ID",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) =>
        params.value ? (
          <Link
            href={`/dashboard/conversations?conversation_id=${params.value}`}
            style={{ textDecoration: "none", color: "blue" }}
          >
            {params.value}
          </Link>
        ) : null,
    },
    {
      field: "infringement_class",
      headerName: "Infringement Type",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "infringement_sub_class",
      headerName: "Infringement Sub Type",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "review_status",
      headerName: "Review Status",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (params: any) => (
        <p style={{ textAlign: "center", margin: 0 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewIncident(params.row.id)}
          >
            View
          </Button>
        </p>
      ),
    },
  ];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter incidents by userId
  const filteredIncidents = allIncidents.filter(
    (incident) => incident.user_id === userId
  );

  // Calculate the counts for each review status
  const statusCounts = filteredIncidents.reduce(
    (acc, incident) => {
      if (incident.review_status === ReviewStatus.AWAITING_REVIEW)
        acc.awaitingReview++;
      if (incident.review_status === ReviewStatus.CONFIRMED_SAFE)
        acc.confirmedSafe++;
      if (incident.review_status === ReviewStatus.REJECTED_UNSAFE)
        acc.rejectedUnsafe++;
      return acc;
    },
    { awaitingReview: 0, confirmedSafe: 0, rejectedUnsafe: 0 }
  );

  if (userId && filteredIncidents.length === 0) {
    return null;
  }

  return (
    <Box sx={{ paddingTop: "8px" }}>
      {userId && (
        <Box sx={{ paddingBottom: "16px" }}>
          <Typography variant="h6" gutterBottom>
            Safety Scorecard
          </Typography>
          {statusCounts.awaitingReview ? (
            <Chip
              label={`Awaiting Review: ${statusCounts.awaitingReview}`}
              color="warning"
            />
          ) : null}
          {statusCounts.confirmedSafe ? (
            <Chip
              label={`Confirmed Safe: ${statusCounts.confirmedSafe}`}
              color="success"
              sx={{ ml: statusCounts.awaitingReview ? 2 : 0 }}
            />
          ) : null}
          {statusCounts.rejectedUnsafe ? (
            <Chip
              label={`Rejected Unsafe: ${statusCounts.rejectedUnsafe}`}
              color="error"
              sx={{
                ml:
                  statusCounts.awaitingReview || statusCounts.confirmedSafe
                    ? 2
                    : 0,
              }}
            />
          ) : null}
        </Box>
      )}
      <Box sx={{ height: userId ? "30vh" : "65vh", width: "100%" }}>
        <DataGrid
          rows={userId ? filteredIncidents : allIncidents}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default SafetyIncidentsTab;
